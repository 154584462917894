import * as types from './types';
import * as Api from '../../api/user';
import User from '../../../models/User';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Role from '../../../models/Role';
import { callToAPI } from '../../../utils/callToAPI';

function* getUser({ payload }) {
  yield callToAPI({
    apiCall: Api.getUser,
    successResponseCode: 200,
    errorType: types.GET_USER_ERROR,
    *callback(response) {
      yield put({
        type: types.GET_USER_SUCCESS,
        payload: new User(response.data),
      });
    },
    payload,
  });
}

function* getUserMe({ payload }) {
  yield callToAPI({
    apiCall: Api.getUser,
    successResponseCode: 200,
    errorType: types.GET_USER_ME_ERROR,
    *callback(response) {
      yield localStorage.setItem('useremail', response.data.email);
      yield put({
        type: types.GET_USER_ME_SUCCESS,
        payload: new User(response.data),
      });
    },
    payload,
  });
}

function* getRoles() {
  yield callToAPI({
    apiCall: Api.getRoles,
    successResponseCode: 200,
    errorType: types.GET_ROLES_ERROR,
    *callback(response) {
      yield put({
        type: types.GET_ROLES_SUCCESS,
        payload: response.data
          .map((role) => new Role(role))
          .filter((role) => role.id_role >= 0),
      });
    },
  });
}

function* createUser({ payload }) {
  yield callToAPI({
    apiCall: Api.createUser,
    successResponseCode: 201,
    errorType: types.CREATE_USER_ERROR,
    *callback(response) {
      yield put({
        type: types.CREATE_USER_SUCCESS,
        payload: new User(response.data),
      });
    },
    payload,
  });
}

function* editUser({ payload }) {
  yield callToAPI({
    apiCall: Api.editUser,
    successResponseCode: 200,
    errorType: types.EDIT_USER_ERROR,
    *callback(response) {
      if (payload.password && payload.repassword) {
        const res = yield call(Api.updateUserPassword, {
          id: payload.id,
          new_password: payload.password,
          old_password: payload.old_password,
        });

        if (res.status === 200) {
          yield put({
            type: types.EDIT_USER_SUCCESS,
            payload: new User(response.data),
          });
        } else {
          yield put({
            type: types.UPDATE_USER_PASSWORD_ERROR,
            payload: {
              status: res.status,
              errorType: types.UPDATE_USER_PASSWORD_ERROR,
              message: res.data?.message,
            },
          });
        }
      } else {
        yield put({
          type: types.EDIT_USER_SUCCESS,
          payload: new User(response.data),
        });
      }
    },
    payload,
  });
}

function* editUserMe({ payload }) {
  yield callToAPI({
    apiCall: Api.editUser,
    successResponseCode: 200,
    errorType: types.EDIT_USER_ME_ERROR,
    *callback(response) {
      if (payload.password && payload.repassword) {
        const res = yield call(Api.updateUserPassword, {
          id: payload.id,
          new_password: payload.password,
          old_password: payload.old_password,
        });

        if (res.status === 200) {
          yield put({
            type: types.EDIT_USER_ME_SUCCESS,
            payload: new User(response.data),
          });
        } else {
          yield put({
            type: types.UPDATE_USER_PASSWORD_ERROR,
            payload: {
              status: res.status,
              errorType: types.UPDATE_USER_PASSWORD_ERROR,
              message: res.data?.message,
            },
          });
        }
      } else {
        yield put({
          type: types.EDIT_USER_ME_SUCCESS,
          payload: new User(response.data),
        });
      }
    },
    payload,
  });
}

function* updateUserPassword({ payload }) {
  yield callToAPI({
    apiCall: Api.updateUserPassword,
    successResponseCode: 200,
    errorType: types.UPDATE_USER_PASSWORD_ERROR,
    *callback() {
      yield put({
        type: types.UPDATE_USER_PASSWORD_SUCCESS,
      });
    },
    payload,
  });
}

function* deleteUser({ payload }) {
  yield callToAPI({
    apiCall: Api.deleteUser,
    successResponseCode: 200,
    errorType: types.DELETE_USER_ERROR,
    *callback(response) {
      yield put({
        type: types.DELETE_USER_SUCCESS,
        payload,
      });
    },
    payload,
  });
}

function* getUsers({ payload }) {
  yield callToAPI({
    apiCall: Api.getUsers,
    successResponseCode: 200,
    errorType: types.GET_USERS_ERROR,
    *callback(response) {
      yield put({
        type: types.GET_USERS_SUCCESS,
        payload: {
          users: response.data.data.map((user) => {
            return new User(user);
          }),
          total: response.data.total,
        },
      });
    },
    payload,
  });
}

/* watchers */
function* watcherGetUser() {
  yield takeEvery(types.GET_USER_REQUEST, getUser);
}

function* watcherGetUserMe() {
  yield takeEvery(types.GET_USER_ME_REQUEST, getUserMe);
}

function* watcherGetUsers() {
  yield takeEvery(types.GET_USERS_REQUEST, getUsers);
}

function* watcherCreateUser() {
  yield takeEvery(types.CREATE_USER_REQUEST, createUser);
}

function* watcherDeleteUser() {
  yield takeEvery(types.DELETE_USER_REQUEST, deleteUser);
}

function* watcherGetRoles() {
  yield takeEvery(types.GET_ROLES_REQUEST, getRoles);
}

function* watcherEditUser() {
  yield takeEvery(types.EDIT_USER_REQUEST, editUser);
}
function* watcherEditUserMe() {
  yield takeEvery(types.EDIT_USER_ME_REQUEST, editUserMe);
}

function* watcherChangeUserPassword() {
  yield takeEvery(types.UPDATE_USER_PASSWORD_REQUEST, updateUserPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetUser),
    fork(watcherGetUserMe),
    fork(watcherGetUsers),
    fork(watcherCreateUser),
    fork(watcherDeleteUser),
    fork(watcherGetRoles),
    fork(watcherEditUser),
    fork(watcherEditUserMe),
    fork(watcherChangeUserPassword),
  ]);
}
