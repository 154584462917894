import { Descriptions } from 'antd';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';

const { description } = styles;

const NCResume = ({ preregister }) => {
  const intl = useIntl();
  const { non_conformity_data: nonConformityData } = preregister.prm_data;

  return (
    <>
      <Descriptions
        className={description}
        title={intl.formatMessage({
          id: 'preregisterWizard.section.nc.resume.title',
        })}
        contentStyle={{ width: '50%' }}
        bordered
        column={{
          xxl: 2,
          xl: 2,
          lg: 2,
          md: 1,
          sm: 1,
          xs: 1,
        }}
        items={[
          {
            label: intl.formatMessage({ id: 'nc.created' }),
            children: nonConformityData.created,
          },
          {
            label: intl.formatMessage({ id: 'nc.resume' }),
            children: nonConformityData.resume,
          },
          {
            label: intl.formatMessage({ id: 'nc.responsible' }),
            children: localStorage.getItem('useremail'),
          },
          {
            label: intl.formatMessage({ id: 'nc.description' }),
            children: nonConformityData.description,
          },
          {
            label: intl.formatMessage({ id: 'nc.detected_by' }),
            children: nonConformityData.detected_by,
          },
          {
            label: intl.formatMessage({ id: 'nc.location' }),
            children: nonConformityData.location,
          },
          {
            label: intl.formatMessage({ id: 'nc.process' }),
            children: nonConformityData.process,
          },
          {
            label: intl.formatMessage({ id: 'nc.type' }),
            children: nonConformityData.type,
          },
        ]}
      />
    </>
  );
};

NCResume.propTypes = {
  preregister: PropTypes.shape({
    prm_data: PropTypes.shape({
      non_conformity_data: PropTypes.shape({
        created: PropTypes.string,
        resume: PropTypes.string,
        responsible: PropTypes.string,
        description: PropTypes.string,
        detected_by: PropTypes.string,
        location: PropTypes.string,
        process: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }),
};

NCResume.defaultProps = {
  preregister: {},
};

export default NCResume;
