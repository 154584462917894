import { Descriptions } from 'antd';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';

const { listItem, description } = styles;

const PreregisterResume = ({ preregister }) => {
  const intl = useIntl();
  const items = [
    {
      label: intl.formatMessage({ id: 'docket_number' }),
      children: preregister.docket_number,
    },
    {
      label: intl.formatMessage({ id: 'arriving_time' }),
      children: preregister.arriving_time,
    },
    {
      label: intl.formatMessage({ id: 'arriving_date' }),
      children: preregister.arriving_date,
    },
    {
      label: intl.formatMessage({ id: 'start_discharging_time' }),
      children: preregister.start_discharging_time,
    },
    {
      label: intl.formatMessage({ id: 'mash_control' }),
      children: intl.formatMessage({
        id: preregister.mash_control ? 'yes' : 'no',
      }),
    },
    {
      label: intl.formatMessage({ id: 'specimens_number' }),
      children: preregister.specimens_number || 'N/A',
    },
    {
      label: intl.formatMessage({ id: 'slump' }),
      children: preregister.slump || 'N/A',
    },
    {
      label: intl.formatMessage({ id: 'mash_control_extra' }),
      children: preregister.mash_control_extra
        ? intl.formatMessage({
            id: preregister.mash_control ? 'yes' : 'no',
          })
        : 'N/A',
    },
    {
      label: intl.formatMessage({ id: 'finish_discharging_time' }),
      children: preregister.finish_discharging_time,
    },
    {
      label: intl.formatMessage({ id: 'detailed_location' }),
      children: preregister.detailed_location,
    },
    {
      label: intl.formatMessage({ id: 'elements' }),
      children: (
        <span>
          {!preregister.elements ? (
            `${intl.formatMessage({ id: 'notElements' })}`
          ) : (
            <ul>
              {preregister.elements.split(',').map((element, index) => (
                <li className={listItem} key={index}>
                  {intl.formatMessage({ id: element.trim() })}
                </li>
              ))}
            </ul>
          )}
        </span>
      ),
    },
  ];
  if (preregister.model === 'cemex') {
    items.push(
      {
        label: intl.formatMessage({ id: 'manufacture_time' }),
        children: preregister.manufacture_time,
      },
      {
        label: intl.formatMessage({ id: 'plant' }),
        children: preregister.plant,
      },
      {
        label: intl.formatMessage({ id: 'recipe' }),
        children: preregister.recipe,
      },
      {
        label: intl.formatMessage({ id: 'delivered' }),
        children: preregister.delivered,
      },
    );
  }
  return (
    <Descriptions
      bordered
      className={description}
      column={{
        xxl: 2,
        xl: 2,
        lg: 2,
        md: 1,
        sm: 1,
        xs: 1,
      }}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.resume.title',
      })}
      items={items}
    />
  );
};

PreregisterResume.propTypes = {
  preregister: PropTypes.shape({
    docket_number: PropTypes.string,
    arriving_date: PropTypes.string,
    arriving_time: PropTypes.string,
    start_discharging_time: PropTypes.string,
    finish_discharging_time: PropTypes.string,
    mash_control: PropTypes.bool,
    mash_control_extra: PropTypes.bool,
    specimens_number: PropTypes.number,
    slump: PropTypes.number,
    detailed_location: PropTypes.string,
    elements: PropTypes.string,
    manufacture_time: PropTypes.string,
    plant: PropTypes.string,
    recipe: PropTypes.string,
    delivered: PropTypes.string,
    model: PropTypes.string,
  }),
};

PreregisterResume.defaultProps = {
  preregister: {},
};

export default PreregisterResume;
