import React from 'react';

const Footer = () => {
  return (
    <div className="footer text-center">
      <span style={{ color: '#FFFFFF' }}>
        ACCIONA © 2025 All rights reserved
      </span>
    </div>
  );
};

export default Footer;
